@keyframes blinking {
	0% {
	  background-color: blue;
	  border: 3px solid #66a6;
	}
	100% {
	  background-color: yellow;
	  border: 3px solid #a666;
	}
}

.highlighter {
	animation: blinking 1s linear 0s 5
}

