@media (max-width: 400px) {
    .tile-container {
        grid-template-columns: auto auto;
    }
}

@media (prefers-reduced-motion: reduce) {
    #tsparticles {
        display: none;
    }
}

@media (max-width: 400px) {
    .tile-container {
      grid-template-columns: auto auto;
    }
  }
  
@media (max-width: 440px) {
    .profile-page-actions-container {
        min-width: 300px;
    }

    .main-panel-left-part {
        min-width: 300px;
    }

    .leaderboard-table th {
        text-align: center;
    }

    .profile-page-table-div {
        min-width: 300px;
    }

    .carousel .carousel-indicators {
        display: none;
    }

    .carousel-item .carousel-caption {
        bottom: -1rem;
    }
}

@media (max-height: 450px) {
    .left-panel-main {
        display: none;
    }

    .container-main {
        margin-left: 0px;
    }
}


@media (max-width: 720px) {
    .left-panel-main {
        display: none;
    }

    .container-main {
        margin-left: 0px;
    }
}

@media (max-width: 900px) {
    .flex-panel {
        flex-wrap: wrap;
    }
}

@media (max-width: 1000px) {
    .account-panel-info {
        top: 75px;
    }
    .account-panel {
        height: 120px;
    }
}

@media (max-width: 470px) {
    .account-panel-info {
        top: 75px;
    }

    .account-panel-info .flex-panel {
        flex-direction: column;
    }

    .account-panel {
        height: 180px;
    }
}

@media (max-width: 470px) {
    .logo-container {
        overflow: hidden;
        max-width: 120px;
    }
}

@media (max-width: 1000px) {
    .profile-main-panel {
        flex-wrap: wrap;
    }
    .profile-info-panel {
        max-width: none;
    }
}

@media (max-width: 700px) {
    h1 {
        font-size: 30px  !important;
    }
}

@media (max-width: 650px) {
    .tile-grid  {
        grid-template-columns: auto;
    }
}

@media (max-width: 700px) {
    .modal-dialog.fp-modal-form  {
        padding: 0px;
    }
}

@media (min-width: 700px) {
    .modal-dialog.fp-modal-form  {
        min-width: 600px;
    }
}