:root {
  --fp-main-color: #B434F0;
  --fp-block-background: #13181D;
  --fp-item-background: #1B2126;
}

.card-title {
  --bs-card-title-color: #ffffff;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #0D1115 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main {
  color: white;
}

.background {
  background: rgb(33, 32, 32);
  background-repeat: repeat;
  background-image: url("static/portal-bg-repeat.png");;
  position: fixed;
  z-index: -1099;
  width: 100%;
  height: 100%;
  display: none;
}

.account-panel {
  margin-top: 10px;
  width: 100%;
}

.account-panel-info .iconTitleTile  {
  width: 220px;
}

.account-panel .login-element {
  right: 10px;
  position: absolute;
}

.account-panel-info {
  position: absolute;
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
}

.container-main {
  padding: 20px;
  margin-left: 120px;
}

.logo-block {
  color: white;
}

.logo-image {
  color: white;
  max-height: 50px !important;
  margin-left: 20px;
}

.logo-container {
  position: relative;
  z-index: 1;
}

#tsparticles {
	position: absolute;
  width: 100%;
  height: 100%;
	margin: 0;
	opacity: 1;
  padding: 0;
  z-index: -1;
}

.account-panel #tsparticles {
  height: auto;
  width: auto;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
}

.table-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
}

table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.tile {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(45deg, purple, grey);
  border-radius: 10px;
  padding: 5px;
  width: 110px;
  height: 110px;
}

.value-action-tile {
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid purple;
  min-width: 9.5rem;
  height: 7rem;
  padding: 5px;
}

.value-action-tile-title {
  border-bottom: 1px dotted purple;
}

.value-action-tile img {
  width: 24px;
  height: 24px;
}

.value-action-tile-action {
  position: absolute;
  bottom: 2px;
  left: 10px;
  right: 10px;
}

.value-action-tile-action :first-child {
  width: 100%;
}

.tile-container {
  width: fit-content;
  height: fit-content;
  display: inline-flex;
  gap : 20px;
  flex-wrap: wrap;
  background: transparent;
  grid-template-columns: auto auto auto auto;
}

.tile-title {
  text-align: center;
  font-weight: bold;
}

.tile-value {
  text-align: center;
}

.block-panel-main {
  background-color: var(--fp-block-background);
  border-radius: 20px;
  padding: 1rem;
  border-radius: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: rgba(255, 255, 255, 0.65);
}

.block-panel-main.panel-row {
  align-items: normal;
}

.cool-list li {
  list-style: none;
}

.cool-list li::before {
  content: "\2022";
  color: var(--fp-main-color);
  scale: 2;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.main-panel.flex-panel {
  align-items: normal;
  min-height: 300px;
}

.main-panel-description-part {
  flex: 1 1 40rem;
  max-width: 50rem;
  display: inline-flex;
  flex-direction: column;
}

.main-panel-description-part .block-panel-main {
  height: 100%;
}

.main-panel-description-part p {
  text-indent: 2rem;
}

.main-panel-description-part p {
  text-align: justify;
}

.main-panel-table-part {
  overflow: hidden;
  padding: 5px;
  flex: 1 1 40rem;
  display: inline-flex;
  flex-direction: column;
  border-radius: 15px;
}

.main-panel-table-part .main-panel-view-button {
  z-index: 2;
  width: 150px;
  align-self: center;
}

.leaderboard-table { 
  color: aliceblue;
  border-collapse: separate; 
  border-spacing: 0 10px; 
  margin-top: -10px; /* correct offset on first border spacing if desired */
}

.leaderboard-table thead tr {
  background: var(--fp-item-background);
  border-radius: 5px;
}

.leaderboard-table tbody tr {
  margin-bottom: 5px;
  padding: 10px;
  background: rgba(180, 52, 240, 0.05);
  border: 1px solid rgba(180, 52, 240, 0.25);
  border-radius: 10px;
}

.MuiPagination-root button {
    color: rgb(198, 76, 198);
}

.fp-modal-form .modal-content {
  background: transparent;
  border: none;
}

.modal-dialog.fp-modal-form  {
  background: rgba(180, 52, 240, 0.5);
  padding: 50px;
  border-radius: 20px;
  backdrop-filter: blur(12.5px);
	border: none;
  color: white;
  font-weight: 400;
  font-size: 16px;
} 

.fp-modal-form a {
  color: var(--fp-main-color);
}

h1 {
  font-weight: 700 !important;
  font-size: 40px !important;
  line-height: 60px !important;
  text-transform: uppercase;
  color: #FFFFFF;
}

h2 {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;

  text-transform: uppercase;
  color: #FFFFFF;
}

h3 {
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #FFFFFF;
}

.user-box input:not([type=submit]) {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
  border: none;
  text-align: center;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
} 

.dropdown-menu.show {
  animation: animateShow 0.15s linear;
}

@keyframes animateShow {
  from {opacity: 0;}
  to {opacity: 1;}
}

.editable-label input,label {
  font-size: 18px;
  color: #fff;
  font-weight: 400;

  width: 100%;

  border: none;
  border-radius: 3px;
  
  outline: none;
  background: transparent;
}

.editable-label {
  display: inline-flex;
  align-items: center;
}

.editable-label input:invalid {
    background: red;
}

.editable-label input:valid {
    background: transparent;
}

.editable-label svg {
  padding: 2px;
  margin-left: 3px;
}

.main-color {
  color: var(--fp-main-color);
}

.hint-icon {
  position: absolute;
  padding: 3px;
  top: 1px;
  right: 1px;
  width: 30px;
  height: 30px;
}

.leaderboard-table-place div {
  background-image: url("static/badge-icon.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  background-blend-mode: luminosity;

  display: flex;
  width: fit-content;
  align-items: center;
  min-width: 32px;
  padding-left: 5px;
  padding-right: 5px;
  aspect-ratio: 1;
  text-align: center;
}

.leaderboard-table-place span {
  width: 100%;
}

.leaderboard-table-reward {
  height: 100%;
}

.description-text-box {
  margin: 2px;
  border-radius: 5px;
  padding: 5px;
  text-align: justify;
}

a .carousel-control-next-icon,a .carousel-control-prev-icon  {
  display: none;
}

.container-main .profile-page-tables-container {
  align-items: baseline;
}


.profile-page-table-div {
  min-width: 400px;
  max-width: 550px;
}

.dropdown .dropdown-menu {
  text-transform: uppercase;
  background: var(--fp-item-background);
  width: 100%;
}

.dropdown .dropdown-item {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.5);
}


.dropdown .dropdown-item:hover {
  color: rgba(255, 255, 255, 0.5);
  background: var(--fp-item-background);
}

.dropdown .pointer.dropdown-item:hover {
  color: var(--fp-main-color);
}


.profile-page-actions-container {
  min-width: 400px;
}


/* Workaround for autofill background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s;
}

.vertical-bar {
	border-left: 2px dotted rgb(239, 16, 239);
	height: 45px;
	position: relative;
	margin-left: -1px;
}

.panel-column h4 {
  font-weight: 600;
}

.footer {
  margin-top: 50px;
  background: var(--fp-block-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}

.partners-list {
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.partners-list img {
  width: 64px;
  height: 64px;
}

.tos-page {
  margin-top: 1rem;
  word-break: normal;
  overflow-wrap: break-word;
}

.lootbox-panel {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 0px 20px;

  height: 150px;
  
  background: var(--fp-item-background);
  border-radius: 10px;
}

.lootbox-panel h3 {
  max-width: 100px;
}

.rewards-list-tooltip-container img {
  width: 45px;
  height: 45px;
}

.rewards-list-tooltip-container {
  padding: 15px;
  background: rgba(180, 52, 240, 0.5);
  backdrop-filter: blur(12.5px);
  border-radius: 10px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: min(60%, 600px);
}

.reward-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.reward-list img {
  width: 24px;
  height: 24px;
}

.connected-wallet-panel {
  display: flex;
  flex-direction: column;
}

.connected-wallet-item {
  height: 58px;

  background: var(--fp-item-background);
  border-radius: 10px;

  display: flex;
  align-items: center;

  padding-left: 10px;
  margin-top: 4px;

  color: white;
}

.connected-wallet-panel button {
  max-width: 200px;
}

.nft-bonus-element {
  display: inline-flex;
  width: 100%;
  background: var(--fp-item-background);
  border-radius: 10px;
  position: relative;
  height: 135px;
}

.nft-bonus-element img {
  height: 100%;
  border-radius: 10px;
}

.nft-bonus-element b {
  border-bottom: 1px solid var(--fp-main-color);
}

.nft-bonus-element div {
  display: inline-flex;
  flex-direction: column;
  margin-left: 5px;
}

.news-element {
  height: 90px;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: rgba(180, 52, 240, 0.1);
  color: #FFFFFF;
}

.news-element-image {
  width: 58px;
  height: 58px;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: inline-flex;
  margin-left: 11px;
}

.news-element-image img {
  border-radius: 10px;
}

.iconTitleTile {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15px;
  width: 15rem;
}

.iconTitleTile-icon {
  width: 58px;
  height: 58px;
  background: radial-gradient(50% 50% at 50% 50%, #461F58 0%, var(--fp-item-background) 100%);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.iconTitleTile-texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.iconTitleTile-title {
  color: rgba(255, 255, 255, 0.5);
}

.iconTitleTile-value {
  font-weight: 700;
  font-size: 24px;
  color: var(--fp-main-color);
}

.default-btn {
  display: block;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-width: 150px;

  background-color: transparent;
  color: white;

  border: 2px solid var(--fp-main-color);
  border-radius: 10px;

  font-weight: 600;
  font-size: 14px;

  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.default-btn.white {
  border: 2px solid white;
  color: white;
}

.default-btn:hover {
  background-color: var(--fp-main-color);
  transition: background-color 0.2s ease-in-out 0s;
  color: white !important;
}

.default-tile-header {
  font-family: 'Poppins';
  
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  text-transform: uppercase;
}

.cool-link {
  width: 100px;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 5px;
  
  background: var(--fp-block-background);
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: white;
}

.left-panel-main {
  padding: 5px;
  position: fixed;
  top: 75px;
  bottom: 75px;
  width: 130px;
  align-items: center;
  display: flex;
  overflow: auto;
  gap: 10px;
  flex-direction: column;
}

.left-panel-main .cool-link {
  filter: opacity(0.8);
  transition: filter 0.2s ease-in-out 0s;
}

.left-panel-main .cool-link:hover {
  filter: opacity(1);
}

.tile-grid {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  row-gap: 10px;
}

a:hover {
  color: var(--fp-main-color) !important;
}

.titleValueLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
  
  height: 57px;
  
  background: var(--fp-item-background);
  border-radius: 10px;
  margin-top: 4px;
}

.actionButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 6px;
  gap: 5px;

  width: 104px;
  height: 60px;

  border: 1px solid var(--fp-main-color);
  border-radius: 5px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-transform: uppercase;

  color: #FFFFFF;
}

.drop-shadow-icon:hover{
  filter: drop-shadow(0px 0px 15px #B434F0);
}

.profile-main-panel {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.profile-info-panel {
  width: 100%;
  max-width: 600px;
}

.inventory-main-panel {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 10px;
  max-width: 1000px;
}

.balances-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-content: flex-start;
}

.inventory-grid {
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.inventory-grid .lootbox-panel {
  flex-grow: 1;
  width: 250px;
  max-width: 300px;
}

.currencyElement {
  display: inline-block;
  width: 335px;
  height: 150px;
  flex-grow: 1;
}

.leaderboard-info-panel {
  flex-wrap: wrap;
}