:root {
  --loading-grey: #ededed;
}

.animRotateY {
  animation: rotateY360 1.5s linear infinite;
}

@keyframes rotateY360 {
  to {
    transform: rotateY(360deg);
  }
}

.loading-skeleton {
  background-color: var(--loading-grey);
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .99) 50%,
    rgba(255, 255, 255, 0) 60%
  ) var(--loading-grey);
  background-size: 200% 100%;
  border-radius: 8px;
  opacity: 0.8;
  background-position-x: 180%;
  animation: 1s background-shift ease-in-out infinite;
}

@keyframes background-shift {
  to {
    background-position-x: -20%;
  }
}
 