.centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.overlay {
	display: flex;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.padding-row-1 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.flex-gap-1 {
	gap: 1rem;
}

.flex-gap-2 {
	gap: 3rem;
}


.flex-wrap {
	flex-wrap: wrap;
}

.flex-panel {
	align-items: center;
	position: relative;
	display: inline-flex;
	justify-content: space-evenly;
	gap: 10px;
}

.panel-row {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
}
  
.panel-column {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
}

.align-normal {
	align-items: normal;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide  {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.width100 {
	width: 100%;
}

.pointer {
	cursor: pointer;
}

.white {
	color:white;
}

.font-heavy {
	font-weight: 600;
}